import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://5cc87206c34760dd68b69913d7d075c6@o4504498832146432.ingest.us.sentry.io/4507106872459264",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});